import React from 'react';
import WeUI from 'react-weui';
import config from '../util/config';
import Back from '../img/back.svg';
import IosImg from '../img/ios.svg';
import AndroidImg from '../img/android.svg';
import Index from '../img/index.png';
import DoctorTeam from '../img/doctorTeam.png';

const { Button,ButtonArea,} = WeUI;

export default class LoadApp extends React.Component {

    clickHandle(url) {
        this.props.history.push(url)
    }

    loadAndroidApp() {
        window.location.href = 'http://221.232.157.75:8888/hbzyy-app-server/services/download/android'
    }

    render(){
        return(
            <section>
            {/*<img src={Back} style={{ marginLeft:'2%', marginTop:'2%' }} onClick={this.clickHandle.bind(this,'/examReport')}></img>
        <div style={{float: 'right',width: '65%',fontSize: '22px',marginTop:'2%'}}>湖北中医院互联网医院</div>*/}
            <div style={{width: '100%',fontSize: '18px',marginTop:'2%', textAlign:'center'}}>湖北省中医院互联网医院</div>
            <ButtonArea direction="horizontal">
                <img src={Index} alt style={{ width:'48%', height:'5%', marginRight:'4%' }}/>
                <img src={DoctorTeam} alt style={{ width:'48%', height:'5%' }}/>
            </ButtonArea>
            <ButtonArea direction="horizontal">
                <img src={IosImg} alt style={{ width:'50%', height:'50px' }}/>
                <img src={AndroidImg} alt style={{ width:'50%', height:'50px' }}/>
            </ButtonArea>
            <ButtonArea direction="horizontal">
                <Button disabled>iOS下载</Button>
                <Button type="primary" plain onClick={this.loadAndroidApp.bind(this)}>Android下载</Button>
            </ButtonArea>
            <span style={{ fontSize:15, color:'#048EE2', marginLeft:'4%'}}>提示:iOS下载敬请期待</span>
            </section>
        )
    }

}