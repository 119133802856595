import React from 'react';
import WeUI from 'react-weui';
import config from '../util/config';
import validateIdNo from '../util/validateIdNo';

const { Dialog, Page, Button, CellsTitle, Form, FormCell, CellHeader, CellBody, Label,
    Input, CellFooter, Icon, ButtonArea, Toast, Toptips } = WeUI;

export default class UserPage extends React.Component {

    constructor(props) {
        super(props);
        //获取openId
        const urlInfo = location.href
        let openId = ''
        if (urlInfo.indexOf('/') > 0) {
            let strs = urlInfo.split("/");
            let data = strs.filter((item) => item.indexOf('code') === 1)
            let arr = data[0].split('=')
            openId = arr[1].split('&')[0]
        }
        this.state = {
            phoneNumber: '',//手机号
            name: '',//姓名
            imageUrl: '',
            imageCardHandle: '',
            idCard: '',
            messageCode: '',
            randomData: '',
            countBtn: false,
            count: 60,
            show: false,
            dialogMess: '',
            style1: {
                buttons: [
                    {
                        label: '确定',
                        onClick: this.hideDialog.bind(this)
                    }
                ]
            },
            flag: false,
            messageShow: false,
            message: {
                buttons: [
                    {
                        label: '确定',
                        onClick: this.hideMessage.bind(this)
                    }
                ]
            },
            idCardFlag: false,
            idCardShow: false,
            idCardDialog: {
                buttons: [
                    {
                        label: '确定',
                        onClick: this.hideIdCard.bind(this)
                    }
                ]
            },
            openId: openId,
            showWarn: false,
            warnText: ''
        }
    }
    componentDidMount() {
        let randomData = this.random(9)
        this.imageCode(randomData)
        this.setState({ randomData })
    }
    clickHandle(url) {
        this.props.history.push(url)
    }
    //点击确定校验
    hideDialog() {
        this.setState({
            show: false,
        });
    }
    //校验验证码
    hideMessage() {
        this.setState({
            messageShow: false
        })
    }
    //检验身份证号
    hideIdCard() {
        this.setState({
            idCardShow: false
        })
    }

    getCode() {
        const { phoneNumber, imageCardHandle, randomData } = this.state
        let phoneReg = /^[1][3,5,7,8,9][0-9]{9}$/
        if (!phoneNumber) {
            this.setState({
                messageShow: true,
                flag: true
            })
            return false
        } else if (imageCardHandle == '') {
            this.setState({
                show: true,
                dialogMess: '请先输入图片验证码'
            })
            return false
        } else if (!phoneReg.test(phoneNumber)) {
            this.setState({
                messageShow: true,
                flag: false
            })
            return false
        }
        this.countTime();
        var options = {
            method: 'GET',
        };
        let url = config.api.getCode + "?phoneNumber=" + phoneNumber + '&deviceId=' + randomData + '&imageCode=' + imageCardHandle
        fetch(url, options).then((response) => {
            if (!response.ok) {
                console.log('response.ok===', response);
            } else {
                return response.json();
            }
        }).then((json) => {
            this.state.msgButton = false
            this.getCodeCallBack(json);
        }).catch((error) => {
            console.log('error==>', error);
        });
    }

    getCodeCallBack(data) {
        if (!data.success) {
            this.setState({
                showWarn: true,
                warnText: data.datas
            })
        }
        console.log("call", data)
    }

    countTime() {
        this.setState({
            countBtn: true
        })
        this._timer = setInterval(() => {
            let num = this.state.count
            if (num == 0) {
                this._timer && clearInterval(this._timer);
                this.setState({
                    countBtn: false,
                    count: 60
                })
            } else {
                this.setState({ count: num - 1 });
            }
        }, 1000);
    }

    //姓名
    changeHandle(e) {
        let text = e.target.value;
        if (this.props.onChange) this.props.onChange(text, e);
        this.setState({
            name: text,
            showWarn: false,
            warnText: ''
        });
    }

    //电话号码
    changePhoneHandle(e) {
        let text = e.target.value;
        if (this.props.onChange) this.props.onChange(text, e);
        this.setState({
            phoneNumber: text,
            showWarn: false,
            warnText: ''
        });
    }

    //身份证号码
    changeIdCardHandle(e) {
        let text = e.target.value;
        if (this.props.onChange) this.props.onChange(text, e);
        this.setState({
            idCard: text,
            showWarn: false,
            warnText: ''
        });
    }

    //验证码
    changeCodeHandle(e) {
        let text = e.target.value;
        if (this.props.onChange) this.props.onChange(text, e);
        this.setState({
            messageCode: text,
            showWarn: false,
            warnText: ''
        });
    }
    //图片验证码
    imageCardHandle(e) {
        let text = e.target.value;
        if (this.props.onChange) this.props.onChange(text, e);
        this.setState({
            imageCardHandle: text,
            showWarn: false,
            warnText: ''
        });
    }
    submitHandle = (e) => {
        const { phoneNumber, name, idCard, messageCode, code, openId, imageCardHandle } = this.state
        let idCheckResult = validateIdNo.validateIdNo(idCard)
        if (name == "") {
            this.setState({
                show: true,
                dialogMess: '请先输入您的真实姓名'
            })
        } else if (name != "" && phoneNumber == "") {
            this.setState({
                show: true,
                dialogMess: '请先输入您的手机号'
            })
        } else if (name != "" && phoneNumber != "" && idCard == "") {
            this.setState({
                idCardShow: true,
                idCardFlag: true
            })
        } else if (!idCheckResult.success) {//身份证不正确
            this.setState({
                idCardShow: true,
                idCardFlag: false
            })
        } else if (phoneNumber != "" && name != "" && idCard != "" && messageCode == "") {
            this.setState({
                show: true,
                dialogMess: '请先输入验证码'
            })
        } else {
            var options = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: name,
                    userName: phoneNumber,
                    idCard: idCard,
                    password: '',
                    messageCode: messageCode,
                    code: openId,
                }),
                timeout: 20 * 1000
            };

            fetch(config.api.wxband, options).then((response) => {
                if (!response.ok) {
                    console.log('response.ok===', response);
                } else {
                    return response.json();
                }
            }).then((json) => {
                this.getBank(json)
            }).catch((error) => {
                console.log('error==>', error);
            })
        }
    }

    getBank(data) {
        if (data.success) {
            global.idCard = data.datas
            this.clickHandle("/examReport")
        } else {
            this.setState({
                showWarn: true,
                warnText: data.datas
            })
        }
    }
    random(Digits) {
        var str = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
        var res = "";
        for (var i = 0; i < Digits; i++) {
            var id = Math.ceil(Math.random() * 35);
            res += str[id];
        }
        return res;
    }
    // 获取图片
    imageCode = (randomData) => {
        let url = config.api.verifyCode + "?deviceId=" + randomData
        fetch(url).then((response) => {
            if (!response.ok) {
                console.log('response.ok===', response);
            } else {
                return response.json();
            }
        }).then((json) => {
            this.setState({ imageUrl: json.datas })
        }).catch((error) => {
            console.log('error==>', error);
        });
    }

    render() {
        const { phoneNumber, name, idCard, messageCode, countBtn, showWarn, warnText, imageUrl, imageCardHandle, randomData } = this.state
        return (
            <Page transition={true} infiniteLoader={true} ptr={false}>
                <Toptips type="warn" show={showWarn}>{warnText}</Toptips>
                <Toptips type="warn" show={showWarn}>{warnText}</Toptips>
                <CellsTitle>绑定用户信息</CellsTitle>
                <Form onSubmit={this.submitHandle.bind(this)}>
                    <FormCell>
                        <CellHeader>
                            <Label>姓名</Label>
                        </CellHeader>
                        <CellBody>
                            <Input ref="userRef" value={name} type="text" placeholder="请输入您的真实姓名" onChange={this.changeHandle.bind(this)} />
                        </CellBody>
                    </FormCell>
                    <FormCell>
                        <CellHeader>
                            <Label>手机号</Label>
                        </CellHeader>
                        <CellBody>
                            <Input ref="phoneRef" value={phoneNumber} type="tel" placeholder="请输入您的手机号" onChange={this.changePhoneHandle.bind(this)} />
                        </CellBody>
                    </FormCell>
                    <FormCell>
                        <CellHeader>
                            <Label>身份证号</Label>
                        </CellHeader>
                        <CellBody>
                            <Input ref="idCardRef" value={idCard} placeholder="请输入您的身份证号" onChange={this.changeIdCardHandle.bind(this)} />
                        </CellBody>
                    </FormCell>
                    <FormCell>
                        <CellHeader>
                            <Label>图片验证码</Label>
                        </CellHeader>
                        <CellBody>
                            <Input ref="codeRef" value={imageCardHandle} type="text" placeholder="请输入图片验证码" onChange={this.imageCardHandle.bind(this)} />
                        </CellBody>
                        <CellFooter>
                            <img src={`data:image/jpeg;base64,${imageUrl}`} onClick={() => this.imageCode(randomData)} style={{ width: '100px', height: '36px', backgroundColor: 'red', zIndex: 10 }} />
                        </CellFooter>
                    </FormCell>
                    <FormCell>
                        <CellHeader>
                            <Label>验证码</Label>
                        </CellHeader>
                        <CellBody>
                            <Input ref="codeRef" value={messageCode} type="number" placeholder="请输入验证码" onChange={this.changeCodeHandle.bind(this)} />
                        </CellBody>
                        <CellFooter>
                            {!countBtn ? <Button type="vcode" onClick={this.getCode.bind(this)}>获取验证码</Button> :
                                <Button type="vcode">{this.state.count} 秒后重试</Button>}
                        </CellFooter>
                    </FormCell>
                </Form>
                <ButtonArea>
                    <Button onClick={this.submitHandle.bind(this)}>确定</Button>
                </ButtonArea>
                <Dialog type="android" title={this.state.message.title} buttons={this.state.message.buttons} show={this.state.messageShow}>
                    {this.state.flag ? '请先输入您的手机号' : '请输入您正确的手机号'}
                </Dialog>
                <Dialog type="android" title={this.state.idCardDialog.title} buttons={this.state.idCardDialog.buttons} show={this.state.idCardShow}>
                    {this.state.idCardFlag ? '请先输入您身份证号' : '请输入您正确的身份证号'}
                </Dialog>
                <Dialog type="android" title={this.state.style1.title} buttons={this.state.style1.buttons} show={this.state.show}>
                    {this.state.dialogMess}
                </Dialog>
            </Page>
        )
    }
}
