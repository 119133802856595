const APIV1 = '/hbzyy-app-server'
const APPWEB = '/hbzyy-app-wms-server'

module.exports = {
  name: '湖北省中医院互联网医院',
  prefix: 'sinosoft',
  logo: '/logo.png',
  iconFontCSS: '/iconfont.css',
  iconFontJS: '/iconfont.js',
  YQL: [],
  CORS: [],
  openPages: ['/login'],
  apiPrefix: '/yun-his/v1',
  idCard:'',
  api: {
    getCode: `${APIV1}/services/system/msgcode/v2`,//获取验证码
    verifyCode: `${APIV1}/services/system/verifyCode`,//获取图片验证码
    // getCode:`${APIV1}/services/system/msgcode`,//获取验证码
    wxband:`${APIV1}/services/system/wx/band`,//保存用户信息
    bandIdCard:`${APIV1}/services/system/wx/idCard`,
    getRecordsByIdCard:`${APIV1}/services/mc/app/exam/records`,
    getExamReport:`${APIV1}/services/mc/app/exam/depts/summary`,
    loadAndroid:`${APIV1}/services/download/android`,
    band:`${APIV1}/services/healthReport/questionnaire/getQuestionAndOptions`,//获取问卷题目
    submit:`${APIV1}/services/healthReport/questionnaire/addNewQuizAnswer`,
    getQuizAnswers:`${APPWEB}/services/doctorteam/obesityteam/report/returnVisit/getQuizAnswers`,//获取问卷答案
    getDailyPatientListByIdCard:`${APPWEB}/services/doctorteam/obesityteam/report/returnVisit/getDailyPatientListByIdCard`,//获取每天待回访患者列表
  },
}