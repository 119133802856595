import React from 'react';
import WeUI from 'react-weui';
import config from '../util/config';
import validateIdNo from '../util/validateIdNo';
import iconSrc from '../img/findend.png';
import icon from '../img/write.png';
import date from '../img/date.png';     
import nothing from '../img/nothing.png';                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
import moment from 'moment';
import { SSL_OP_ALL } from 'constants';


const {Dialog, Page, Button, CellsTitle, Form, FormCell, CellHeader, CellBody, Label,
    Input, CellFooter, Icon, ButtonArea, Toast, Toptips,Grids,Cells, Cell,Flex,FlexItem} = WeUI;
    
export default class PeriodicList extends React.Component {

    constructor(props) {
        super(props);
        //获取openId
        this.state = {
            editText:"查看",
            dataList:[],
            mainFlag:true
        }    
    }

    clickHandle = item => {
        this.props.history.push({pathname: "/questionnaire", state: {
            item: item ,dataInfo:this.state.dataInfo,idCard:this.state.cardNum
        }})
    }



    //获取url拿到参数加载回访信息
    componentDidMount=()=>{
        let idCard = ""
        if(this.props.location.state==undefined){
            let url = window.location.href; //获取url中"?"符后的字串 
            if (url.indexOf('?') > 0) {
            let str = url.substr(1);
            let strs = str.split("idCard=");   
            idCard = strs[1];
           }
        }else{
            idCard = this.props.location.state.idCard
        }
        this.setState({
            cardNum:idCard
        })
        var options = {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            timeout: 20 * 1000
        }
        //?deptId='+deptId+'&'+'patiType='+ patiType +'&'+'idCard='+cardNum
        fetch(config.api.getDailyPatientListByIdCard+'?deptId=857&patiType=0&idCard'+idCard, options).then((response) => {
            if (!response.ok) {
            } else {
                return response.json();
            }
        }).then((json) => {
            if(!json.datas.inHospNo){
               this.setState({
                 mainFlag:false
               })
            }
            json.datas.outHospDate = moment(json.datas.outHospDate).format('YYYY-MM-DD')
            this.setState({dataInfo:json.datas?json.datas:""})
            this.setState({dataList:json.datas.patiRecords?json.datas.patiRecords:[]})
        }).catch((error) => {
            console.log('error==>', error);
        })
    }

    
    render() {
        const { userName, name, idCard, messageCode, countBtn, showWarn, warnText,urlto,editText,dataList,dataInfo,mainFlag} = this.state
        const paitList  = dataList.map((item,index)=>{
            if(item.isMaturity==1){
                return  <Cell href="javascript:;" access>
                            <CellHeader>
                                <img src={item.status==0?icon:iconSrc} alt="" style={{display: `block`, width: `35px`, marginRight: `5px`}}/>
                            </CellHeader>
                            <CellBody>
                                {item.returnVisitCycle==0.25?'出院1周健康报告':'出院'+item.returnVisitCycle+'个月健康报告'}
                                {/* 出院{item.returnVisitCycle}个月健康报告<span>{item.outHospWeight}</span> */}
                            </CellBody>
                            <CellFooter onClick={()=>{this.clickHandle(item)}}>
                                {item.status==0 ? '填写':'查看'} 
                                {/* 0是未提交 */}
                            </CellFooter>
                  </Cell>
                 }else{    //没有到日期的时候
                    return <Cell href="javascript:;" access>
                                <CellHeader>
                                    <img src={date} alt="" style={{display: `block`, width: `28px`, marginRight: `5px`}}/>
                                </CellHeader>
                                <CellBody>
                                {item.returnVisitCycle==0.25?'出院1周健康报告':'出院'+item.returnVisitCycle+'个月健康报告'}
                                    {/* 出院{item.returnVisitCycle}个月健康报告<span>{item.outHospWeight}</span> */}
                                </CellBody>
                                <CellFooter>
                                    未到访问日期
                                    {/* 0是未提交 */}
                                </CellFooter>
                      </Cell>
                   }
              })

        return (
            <div>
            {mainFlag? <Page transition={true} infiniteLoader={true} ptr={false}>
              <Cells>
                    <Cell>
                        <CellBody>
                            出院时间
                        </CellBody>
                        <CellFooter>
                           {dataInfo==undefined?"":dataInfo.outHospDate}
                        </CellFooter>
                    </Cell>
                    <Cell>
                        <CellBody>
                            出院体重
                        </CellBody>
                        <CellFooter>
                        {dataInfo==undefined?"":dataInfo.outHospWeight}kg
                        </CellFooter>
                    </Cell>
               </Cells>
                    <CellsTitle>健康报告</CellsTitle>
                    <Cells>
                        {paitList}
                    </Cells>
               </Page>:<Flex>
                            <FlexItem>
                                <div style={{textAlign:'center',paddingTop:'50%'}}>
                                <img src={nothing} alt="" style={{display: `block`, width: `40px`,textAlign:'center',paddingBottom:'15px',margin:'auto',}}/>
                                    抱歉！暂未查到您的出院记录。
                                </div>
                            </FlexItem>
                       </Flex>
               
               }
            </div>
        )
    }
}
