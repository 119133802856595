import React from 'react';
import { render } from 'react-dom'

import WeUI from 'react-weui';
import 'weui';
import 'react-weui/build/packages/react-weui.css';
import './bdstyle.css';
import App from './view/app';


const root = document.getElementById("container")

render(<App />, root)

/**
 * Webpack热更新(hot module replacement)
 */
if (module.hot) {
    module.hot.accept()
}





