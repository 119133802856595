//常用的校验

function validateIdNo(idNo){
    const idNo15To18 = (idNo) => {  //15位转18位身份证号
        if(idNo.length == '15'){
            const arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2)
            const arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2')
            let idNoTemp = 0, i; 
            idNo = idNo.substr(0, 6) + '19' + idNo.substr(6, idNo.length - 6)
            for(i = 0; i < 17; i ++){
                idNoTemp += idNo.substr(i, 1) * arrInt[i]
            }
            idNo += arrCh[idNoTemp % 11]
            return idNo
        }
        return idNo
    };
    //校验位的检测
    const checkParity = (idNo) => {
        //15位转18位
        idNo = idNo15To18(idNo)
        const len = idNo.length
        if(len == '18'){
            const arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2)
            const arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2')
            let idNoTemp = 0, i, valnum
            for(i = 0; i < 17; i ++){
                idNoTemp += idNo.substr(i, 1) * arrInt[i]
            }
            valnum = arrCh[idNoTemp % 11]
            if (valnum == idNo.substr(17, 1)){
                return true
            }
            return false
        }
        return false
    };
    //出生日期校验
    const checkBirthDay = (idNo) => {
        //身份证15位时，次序为省（3位）市（3位）年（2位）月（2位）日（2位）校验位（3位）
        //身份证18位时，次序为省（3位）市（3位）年（4位）月（2位）日（2位）校验位（4位），校验位末尾可能为X
        let year,month,day
        if(idNo.length==15){
            year = '19'+idNo.substr(6,2)
            month = idNo.substr(8,2)
            day  = idNo.substr(10,2)
        }else{
            year = idNo.substr(6,4)
            month = idNo.substr(10,2)
            day = idNo.substr(12,2)
        }
        var birthday = new Date(year+'/'+month+'/'+day);
        return (birthday.getFullYear() == parseInt(year,10))
                 && ((birthday.getMonth() + 1) == parseInt(month,10)) 
                 && (birthday.getDate() == parseInt(day,10))
            
    };
    //开始校验
    if(!/^\d{17}(\d|x|X)$/i.test(idNo) && !/^\d{15}$/i.test(idNo)){
        return {success:false,message:'身份证号位数错误，应为15或18位!'}
    }
    const districts={11:"北京",12:"天津",13:"河北",14:"山西",15:"内蒙古",21:"辽宁",22:"吉林",23:"黑龙江",31:"上海",32:"江苏",33:"浙江",34:"安徽",35:"福建",36:"江西",37:"山东",41:"河南",42:"湖北",43:"湖南",44:"广东",45:"广西",46:"海南",50:"重庆",51:"四川",52:"贵州",53:"云南",54:"西藏",61:"陕西",62:"甘肃",63:"青海",64:"宁夏",65:"新疆",71:"台湾",81:"香港",82:"澳门",91:"国外"}
    if(districts[parseInt(idNo.substr(0,2))]==null) return {success:false,message:'身份证号归属地为非法地区!'}
    const birthdayFlag = checkBirthDay(idNo)
    if(!birthdayFlag) return {success:false,message:'身份证的出生日期错误!'}
    const parityFlag = checkParity(idNo)
    if(!parityFlag) return {success:false,message:'身份证的校验码错误!'}
    return {success:true,message:'校验成功!'}
}

module.exports = {
    //身份证校验
    validateIdNo
}