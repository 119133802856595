import React from 'react'
import { hot } from 'react-hot-loader'
import { HashRouter as Router, Route, Link } from "react-router-dom";
import UserPage from './userPage';//绑定用户信息
import ExamReport from './examReport';//体检报告
import LoadApp from './loadApp';//下载App
import PeriodicList from './periodicList';//周期列表
import Questionnaire from './questionnaire';//周期列表


const App = () => (
    <Router>
        <div>
            <Route exact path="/" component={ExamReport}/>
            <Route path="/userPage" component={UserPage}/>
            <Route path="/examReport" component={ExamReport}/>
            <Route path="/loadApp" component={LoadApp}/>
            <Route path="/periodicList" component={PeriodicList}/>
            <Route path="/questionnaire" component={Questionnaire}/>
        </div>
    </Router>
)

export default hot(module)(App)