import React from 'react';
import WeUI from 'react-weui';
import config from '../util/config';
import Logo from '../img/logo.png';
import moment from 'moment';

const {CellsTitle,Icon,Panel,PanelHeader,PanelBody,MediaBox,MediaBoxTitle,MediaBoxDescription} = WeUI;

const IconBox = (props) => (
    <div style={{ display: 'flex',alignItems: 'center',marginLeft: '8%',}}>
        {props.icon}
        <div style={{flexShrink: 100}}>
            <p style={{marginTop: 6,fontSize: '18px',color: '#888888'}}>{props.desc}</p>
        </div>
    </div>
)

export default class ExamReport extends React.Component {

    constructor(props) {
        super(props);
        //获取访问的url信息
        const urlInfo = location.href
        let code = ''
        if(urlInfo.indexOf('?')>0){
            let str = urlInfo.substr(1);
            let strs = str.split("&");
            code = strs[0].split("=")[1];
        }
        this.state = {
            code: code,
            examData:[],
            patientData:{}
        };
    }

    clickHandle(url) {
        this.props.history.push(url)
    }

    componentWillMount(){
        let idCard = global.idCard
        if(idCard){
            this.getExamRecord(idCard);
        }else{
            this.getBandIdCard();
        }
    }

    getBandIdCard(){//判断第一次是否绑定用户信息
        const { code } = this.state
        var options = {
            method: 'GET',
        };
        let url = config.api.bandIdCard+"?code="+code;
        fetch(url, options).then((response) => {
            if (!response.ok) {
                console.log('response.ok===', response);
            } else {
                return response.json();
            }
        }).then((json) => {
            this.getIdCardCallBack(json);
        }).catch((error) => {
            console.log('error==>', error);
        });
    }

    getIdCardCallBack = (d) => {
        if(d.success){
            if(!d.datas.idCard){//如果idCard为null,跳到绑定用户信息
                this.clickHandle("/userPage/"+d.datas.code)//code为opendId
            }else{
                this.getExamRecord(d.datas.idCard)//通过idCard查询体检记录
            }
        }
    }

    getExamRecord(data){
        global.idCard = data;
        var options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        };
        let url = config.api.getRecordsByIdCard+"?idCard="+data
        fetch(url, options).then((response) => {
            if (!response.ok) {
                console.log('response.ok===', response);
            } else {
                return response.json();
            }
        }).then((json) => {
            this.getExamRecCallBack(json);
        }).catch((error) => {
            console.log('error==>', error);
        });
    }

    getExamRecCallBack (data) {
        this.setState({
            patientData:data.datas[0]
        })
        var options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        };
        let url = config.api.getExamReport+"?patientId="+data.datas[0].patientId
        fetch(url, options).then((response) => {
            if (!response.ok) {
                console.log('response.ok===', response);
            } else {
                return response.json();
            }
        }).then((json) => {
            this.getExamCallBack(json);
        }).catch((error) => {
            console.log('error==>', error);
        });
    }

    getExamCallBack(data) {
        this.setState({
            examData:data.datas
        })
    }

    render(){
        const {examData,patientData} = this.state
        const mediaList = examData.map((item,index)=>{
            return <MediaBox type="text" style={item.flag==0?{backgroundColor:'#fff'}:{backgroundColor:'#EDD1C1'}}>
                    <MediaBoxTitle style={{ fontWeight:'bold'}}>{item.departName}</MediaBoxTitle>
                    <MediaBoxDescription style={{ color:'#000', fontSize: 15, display: 'block' }}>
                        {item.departSummary}
                    </MediaBoxDescription>
                </MediaBox>
        })
        return(
            <div>
            {examData.length>1?
                <Panel>
                {/*<Cell style={{ backgroundColor:'#494949'}}>
                    <CellHeader>
                        <img src={Logo} alt="" style={{display: `block`, width: `58px`, marginRight: `5px`}}/>
                    </CellHeader>
                    <CellBody style={{ color:'#fff', fontSize: 17 }}>
                        下载APP查看更多报告详情免费解读报告
                    </CellBody>
                    <CellFooter style={{ height:'45px' }}>
                        <Button style={{ marginLeft:8 }} onClick={this.clickHandle.bind(this,'/loadApp')}>立即下载</Button>
                    </CellFooter>
                </Cell>*/}
                <PanelHeader>
                    体检报告
                </PanelHeader>
                <PanelBody>
                    <MediaBox type="text" style={{backgroundColor:'#fff'}}>
                        <MediaBoxTitle style={{ fontWeight:'bold'}}>登记时间</MediaBoxTitle>
                        <MediaBoxDescription style={{ color:'#000', fontSize: 15, display: 'block' }}>
                            {moment(patientData.regDate).format('YYYY-MM-DD HH:mm')}
                        </MediaBoxDescription>
                    </MediaBox>
                    <MediaBox type="text" style={{backgroundColor:'#fff'}}>
                        <MediaBoxTitle style={{ fontWeight:'bold'}}>总检时间</MediaBoxTitle>
                        <MediaBoxDescription style={{ color:'#000', fontSize: 15, display: 'block' }}>
                            {moment(patientData.finalDate).format('YYYY-MM-DD HH:mm')}
                        </MediaBoxDescription>
                    </MediaBox>
                    {mediaList}
                    <MediaBox type="text" style={{backgroundColor:'#fff'}}>
                        <MediaBoxTitle style={{ fontWeight:'bold'}}>体检结论</MediaBoxTitle>
                        <MediaBoxDescription style={{ color:'#000', fontSize: 15, display: 'block' }}>
                            {patientData.suggestion}
                        </MediaBoxDescription>
                    </MediaBox>
                </PanelBody>
            </Panel>:
            <div style={{ paddingTop:'50%' }}>
                <IconBox
                    icon={<Icon size="small" value="warn"/>}
                    desc="抱歉,暂时并未查到您的体检报告!"
                />
                <CellsTitle style={{ fontSize:16, marginLeft:'12%' }}>1.请确认在湖北省中医院做过体检</CellsTitle>
                <CellsTitle style={{ fontSize:16, marginLeft:'12%' }}>2.如有疑问，请拨打体检中心热线</CellsTitle>
                <CellsTitle style={{ fontSize:16, marginLeft:'12%' }}>电话号码：027-87748200</CellsTitle>
            </div>
            }
            </div>
        )
    }
}