import React from 'react';
import WeUI from 'react-weui';
import config from '../util/config';
import moment from 'moment';
import { constants } from 'zlib';
// import { CONNREFUSED } from 'dns';

const { Dialog, Page, Button, CellsTitle, Form, FormCell, CellHeader, CellBody, Label,
    Input, CellFooter, Icon, ButtonArea, Toast, Toptips, Select, Cells, Cell, } = WeUI;


export default class Questionnaire extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showWarn: false,
            womanOption: false,
            show: false,
            dialogMess: '',
            warnText: '',
            questionsDate: [],
            answers: [],
            btnFlag:true,
            form: {
            },
            style1: {
                buttons: [
                    {
                        label: '确定',
                        onClick: this.hideDialog.bind(this)
                    }
                ]
            },
        };
    }

    componentDidMount() {
        const { dataInfo } = this.props.location.state
        this.getInformation(dataInfo)
    }

    getRecord = (id,datas) => {
        fetch(config.api.getQuizAnswers + '?questionnaireId=' + id).then((response) => {
            if (!response.ok) {
                console.log('response.ok===', response);
            } else {
                return response.json();
            }
        }).then((d) => {
            if (d.success) {
                datas.map(item => {
                    d.datas.map(data => {
                        if (data.quizId == item.quizEntity.id) {
                            item.quizEntity.answer = data.answer
                        }
                    })
                })
                console.log('questionsDate==>', datas)
                this.setState({
                    questionsDate: datas
                })
            }
        }).catch((error) => {
            console.log('error==>', error);
        })
    }

    getInformation=(dataInfo)=> {
        var options = {
            method: 'GET',
        }
        fetch(config.api.band + '?sex=' + dataInfo.sex).then((response) => {
            if (!response.ok) {
                console.log('response.ok===', response);
            } else {
                return response.json();
            }
        }).then((json) => {
            this.setState({ questionsDate: json.datas })
            json.datas.map((items, index) => {
                if (items.quizEntity.questionType == 0) {
                    const {item} = this.props.location.state
                    let form = {}
                    form.answer = 'A'
                    form.quizId = items.quizEntity.id
                    form.userId = item.inHospNo
                    this.state.answers.push(form)
                }
            })
            const { item } = this.props.location.state
            if (item.status == 1) { // 查看
                this.getRecord(item.id,json.datas)
                this.setState({
                    btnFlag:false
                })
            }
        }).catch((error) => {
            console.log('error==>', error);
        })
    }

    //获取添加表单的value值
    handleChange = (key, event) => {
        let form = {}
        let temp_formdata = {}
        let flag = false
        try {
            this.state.answers.forEach((item) => {
                if (item.quizId == key) {
                    temp_formdata = item
                    flag = true
                    throw new Error("找到目标")
                }
            })
        } catch (e) {
            if (e.message != '找到目标') throw e
        }
        if (flag) {
            temp_formdata.answer = event.target.value
        } else {
            if (key) {
                form.answer = event.target.value
                form.quizId = key
                form.userId = 15006153112
            }
            this.state.answers.push(form)
        }
        console.log(this.state.answers);
    }

    //点击确定校验
    hideDialog() {
        this.setState({
            show: false,
        });
    }
    
    //返回上一级页面
    back=()=>{
       this.props.history.push("/periodicList")
    }

    closeWar=()=>{
        setTimeout(()=> {
            this.setState({showWarn: false});
        }, 2000);
    }
    
    
    
    //提交表单
    submitHandle = () => {
        const { answers, questionsDate } = this.state
        const { item, dataInfo,idCard} = this.props.location.state
        let filterQuestions
        let filterData
        filterQuestions = questionsDate.filter(item => item.quizEntity.id!="0c3f9ecc-1da1-49fc-af7b-0597e9284ddb"&&item.quizEntity.id!="9251e986-4ee5-4a49-9ed7-1832ce55c37e")
        filterData = answers.filter(item => item.quizId!="0c3f9ecc-1da1-49fc-af7b-0597e9284ddb"&&item.quizId!="9251e986-4ee5-4a49-9ed7-1832ce55c37e")
        if(filterQuestions.length!=filterData.length){
            this.setState({
                showWarn: true,
                warnText: '有未填项请填写完整'
            })
            this.closeWar()
            return
        }
        var options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "answers": answers,
                "quizRecordEntity": {
                    "inHospNo": item.inHospNo,
                    "deptId": item.deptId,
                    "deptName": item.deptName,
                    "district":dataInfo.district,
                    "returnVisitCycle": item.returnVisitCycle,
                    "id":item.id,
                    "status":1
                }
            }),
            timeout: 20 * 1000
        };
        fetch(config.api.submit, options).then((response) => {
            if (!response.ok) {
            } else {
                return response.json();
            }
        }).then((json) => {
            if (!json.success) {
                this.setState({
                    showWarn: true,
                    warnText: '保存问卷问题失败!'
                })
                this.closeWar()
            } else {
                this.setState({
                    showWarn: true,
                    warnText: '保存问卷问题成功!'
                })
                 this.closeWar()
                 setTimeout(()=> {
                    this.props.history.push({pathname:"/periodicList",state:{idCard:idCard}})
                }, 2000);
            }
        }).catch((error) => {
            console.log('error==>', error);
        })
    }

    render() {
        const { womanOption, information, form, showWarn, warnText, questionsDate, answers,btnFlag} = this.state
        const { dataInfo } = this.props.location.state
        dataInfo.inHospDate= moment(dataInfo.inHospDate).format('YYYY-MM-DD')
        dataInfo.outHospDate= moment(dataInfo.outHospDate).format('YYYY-MM-DD')
        const questionDate = questionsDate.map((item, index) => {
            if (item.quizEntity.questionType == 2) {
                return <FormCell>
                            <CellHeader>
                                <Label>{item.quizEntity.questionContent}</Label>
                            </CellHeader>
                            <CellBody>
                                <Input defaultValue={item.quizEntity.answer} onBlur={this.handleChange.bind(this, item.quizEntity.id)} />
                            </CellBody>
                       </FormCell>
            }
            if (item.quizEntity.questionType == 0) {
                const quizOptionEntityDate = item.quizOptionEntity.map((item, index) => {
                    return <option value={item.optionNo}>{item.questionOption}</option>
                })
                return <FormCell select selectPos="after">
                            <CellHeader>
                                <Label>{item.quizEntity.questionContent}</Label>
                            </CellHeader>
                            <CellBody>
                                <Select onChange={this.handleChange.bind(this, item.quizEntity.id)} value={item.quizEntity.answer}>
                                    {quizOptionEntityDate}
                                </Select>
                            </CellBody>
                       </FormCell>
            }
        })
        return (
            <div>
                    <Toptips type="warn" show={showWarn}>{warnText}</Toptips>
                    <CellsTitle>患者基本信息 </CellsTitle>
                    <Form ref={"myform"}>
                        {/* <FormCell>
                                    <CellHeader>
                                        <Label>微信</Label>
                                    </CellHeader>
                                    <CellBody>
                                        <Input  value={form.WeChat} type="text"/>
                                    </CellBody>
                                </FormCell> */}
                        <FormCell>
                            <CellHeader>
                                <Label>入院时间</Label>
                            </CellHeader>
                            <CellBody>
                                <Input disabled value={dataInfo.inHospDate} />
                            </CellBody>
                        </FormCell>
                        <FormCell>
                            <CellHeader>
                                <Label>入院体重</Label>
                            </CellHeader>
                            <CellBody>
                                <Input disabled value={dataInfo.inHospWeight+'kg'} />
                            </CellBody>
                        </FormCell>
                        <FormCell>
                            <CellHeader>
                                <Label>出院时间</Label>
                            </CellHeader>
                            <CellBody>
                                <Input disabled value={dataInfo.outHospDate} />
                            </CellBody>
                        </FormCell>
                        <FormCell>
                            <CellHeader>
                                <Label>出院体重</Label>
                            </CellHeader>
                            <CellBody>
                                <Input disabled value={dataInfo.outHospWeight+'kg'} />
                            </CellBody>
                        </FormCell>
                    </Form>
                    <Form>
                        {questionDate}
                    </Form>
                    {btnFlag &&
                           <Button onClick={this.submitHandle.bind(this)}>保存</Button>
                    }
                    <Dialog type="android" title={this.state.style1.title} buttons={this.state.style1.buttons} show={this.state.show}>
                        {this.state.dialogMess}
                    </Dialog>
            </div>
        )
    }
}